// src/reducer/appReducer.ts
//import { AppState, Action } from "../types";
import { ActionTypes } from "./actionTypes";

export const initialState: AppState = {
  isUserLoggedIn: false,
  userID: "",
  userEmail: null,
  selectedLanguage: "",
  sttLanguage: "",
  selectedLanguageJSON: null,
  chatDirection: "ltr",
  selectedFace: "",
  showInput: true,
  chatHistory: [],
  renderAnimation: false,
  sessionID: "",
  isAudioMode: false,
  suggestedExperts: [],
  // Initialize other state properties here
  userLocation: null,
  appTheme: "Light",
  authToken: null,
  myDocuments: null,
  myDocumentsCount: 0,
  jwtToken: null,
  jwtDecoded: null,
  showSmartSuggestionArea: false
};

export const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_STATUS:
      return { ...state, isUserLoggedIn: action.payload };
    case ActionTypes.SET_USER_ID:
      return { ...state, userID: action.payload };

    case ActionTypes.SET_USER_EMAIL:
      return { ...state, userEmail: action.payload };
    case ActionTypes.SET_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    case ActionTypes.SET_LANGUAGE_JSON:
      return { ...state, selectedLanguageJSON: action.payload };
    case ActionTypes.SET_STT_LANGUAGE:
      return { ...state, sttLanguage: action.payload };
    case ActionTypes.SET_LANGUAGE_DIRECTION:
      return { ...state, chatDirection: action.payload };
    case ActionTypes.SET_STELLA_AVATAR:
      return { ...state, selectedFace: action.payload };
    case ActionTypes.SET_SHOW_INPUT:
      return { ...state, showInput: action.payload };
    case ActionTypes.UPDATE_CHAT_HISTORY:
      return { ...state, chatHistory: action.payload };
    case ActionTypes.SET_SUGGESTED_EXPERTS:
      return { ...state, suggestedExperts: action.payload };
    case ActionTypes.SHOW_CHAT_LOADING:
      return { ...state, renderAnimation: !state.renderAnimation };
    case ActionTypes.SET_SESSION_ID:
      return { ...state, sessionID: action.payload };
    case ActionTypes.RENDER_ANIMATION:
      return { ...state, renderAnimation: action.payload };
    case ActionTypes.TOGGLE_AUDIO_MODE:
      return { ...state, isAudioMode: !state.isAudioMode };
    case ActionTypes.SET_USER_LOCATION:
      return { ...state, userLocation: action.payload };

    case ActionTypes.SET_APP_THEME:
      return { ...state, appTheme: action.payload };

    case ActionTypes.SET_AUTH_TOKEN:
      return { ...state, authToken: action.payload };

    case ActionTypes.SET_MY_DOCUMENTS:
      return { ...state, myDocuments: action.payload };

    case ActionTypes.SET_MY_DOCUMENTS_COUNT:
      return { ...state, myDocumentsCount: action.payload };

    case ActionTypes.SET_JWT_TOKEN:
      return { ...state, jwtToken: action.payload };

    case ActionTypes.SET_DECODED_JWT:
      return { ...state, jwtDecoded: action.payload };

      case ActionTypes.SET_SHOW_SMART_SUGGESTIONS:
      return { ...state, showSmartSuggestionArea: action.payload };

    default:
      return state;
  }
};
