import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ArrowUpFromLine, ImageUp, Plus } from "lucide-react";
import { useRef, useState } from "react";
import Drawer from "../components/drawer/Drawer";
import { X } from "lucide-react";
import { useLanguage } from "../context/LanguageContext";
import { Camera } from "lucide-react";

const UploadFileDrop = ({ handleFile }: any) => {
  const { translate, setLanguage } = useLanguage();

  const hiddenImageInput = useRef<HTMLInputElement>(null);
  const hiddenCameraInput = useRef<HTMLInputElement>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const fileUploadIconButtonRef = useRef<any>(null);

  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);

  async function requestCameraPermission(e: any) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      console.log("Camera access granted");
      stream.getTracks().forEach((track) => track.stop()); // Stop the camera stream
      handleClick(e, "CAMERA");
    } catch (err) {
      console.error("Camera access denied", err);
    }
  }

  // Programmatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any, type: string) => {
    event.preventDefault();

    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView?.postMessage(
    //     JSON.stringify({
    //       action: "UPLOAD_FILE_TYPE",
    //       data: type,
    //     })
    //   );
     
    // } else {
    //   handleFileSelection(type);
    // }
    handleFileSelection(type);
    event.target.value = null;
  };

  const handleFileSelection = (type: string) => {
    if (type === "FILE") {
      hiddenFileInput.current?.click();
    } else if (type === "IMAGE") {
      hiddenImageInput.current?.click();
    } else {
      hiddenCameraInput.current?.click();
    }

    fileUploadIconButtonRef?.current?.click();
  };

  function receiveFilePermission(fileType: string, accessStatus: string) {
    //console.log("receiveFilePermission status: ", accessStatus);

    if (accessStatus === "GRANTED") {
      handleFileSelection(fileType);
    } else {
      alert("Please allow file permissions to use this feature!");
    }
  }

  // Make sure this function is accessible globally
  window.receiveFilePermission = receiveFilePermission;

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: any, fileType: string) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      // Check if file size exceeds 10MB
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (fileUploaded.size > maxSizeInBytes) {
        //alert("File size exceeds 10MB. Please select a smaller file.");
        setOpenErrorDialog(true);
        fileUploadIconButtonRef?.current?.click();
        return;
      }
      handleFile(fileUploaded, fileType);

      (document.activeElement as HTMLElement).blur();
      event.target.value = null;
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  // function requestFilePermission() {
  //   const input = document.createElement('input');
  //   input.type = 'file';
  //   input.onchange = () => {
  //     if (input?.files?.length > 0) {
  //       console.log("File access granted");
  //     } else {
  //       console.log("File access denied");
  //     }
  //   };

  // }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={(e: any) => handleChange(e, "IMAGE")}
        ref={hiddenCameraInput}
        style={{ display: "none" }}
        capture="environment"
      />

      <input
        type="file"
        accept="image/*"
        onChange={(e: any) => handleChange(e, "IMAGE")}
        ref={hiddenImageInput}
        style={{ display: "none" }}
      />

      <input
        type="file"
        accept="application/pdf"
        onChange={(e: any) => handleChange(e, "FILE")}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
      <Menu as="div" className="relative inline-block text-left">
        <div className="pb-[3px]">
          <MenuButton
            className="p-1.5 bg-slate-200 dark:bg-slate-200/20 aspect-square w-11 h-11 rounded-full flex items-center justify-center"
            ref={fileUploadIconButtonRef}
          >
            <span className="sr-only">Open options</span>
            <Plus className="h-6 w-6 text-slate-500 dark:text-slate-400" />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="fixed bottom-20 left-4 z-100 w-56 origin-bottom-left rounded-xl bg-primary-50  dark:bg-blue-950 backdrop-blur-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {window.ReactNativeWebView ? (
              <MenuItem>
                <button
                  onClick={(e) => handleClick(e, "CAMERA")}
                  className="px-4 py-3 text-sm text-gray-700 dark:text-white data-[focus]:bg-gray-100 dark:data-[focus]:bg-blue-900 data-[focus]:text-gray-900 inline-flex items-center gap-2 w-full rounded-lg"
                >
                  <Camera className="w-5 h-5" />
                  Capture Image
                </button>
              </MenuItem>
            ) : null}

            <MenuItem>
              <button
                onClick={(e) => handleClick(e, "IMAGE")}
                className="px-4 py-3 text-sm text-gray-700 dark:text-white data-[focus]:bg-gray-100 dark:data-[focus]:bg-blue-900 data-[focus]:text-gray-900 inline-flex items-center gap-2 w-full rounded-lg"
              >
                <ImageUp className="w-5 h-5" />
                Upload Image
              </button>
            </MenuItem>

            <MenuItem>
              <button
                onClick={(e) => handleClick(e, "FILE")}
                className="px-4 py-3 text-sm text-gray-700 dark:text-white data-[focus]:bg-gray-100 dark:data-[focus]:bg-blue-900 data-[focus]:text-gray-900 inline-flex items-center gap-2 w-full rounded-lg"
              >
                <ArrowUpFromLine className="w-5 h-5" />
                Upload Files
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>

      <Drawer isOpen={openErrorDialog} onClose={handleCloseErrorDialog}>
        <div className="relative sm:px-2">
          {/* <button
            className="border border-slate-300 rounded-full p-1 absolute -top-3 right-0 sm:-top-2 sm:-right-2 bg-white shadow-sm"
            onClick={handleCloseErrorDialog}
          >
            <X className="w-4 h-4 text-slate-400" />
          </button> */}
          <h3 className="text-xl font-semibold leading-6 text-gray-900 dark:text-white mt-3 mb-5 sm:text-left">
            {translate("ExceedTitle")}
          </h3>
          <div className="pb-5 sm:pb-0 space-y-3 sm:space-y-5">
            <p className="text-sm sm:text-left">{translate("ExceedContent")}</p>
            <button
              onClick={handleCloseErrorDialog}
              style={{ background: "var(--primary" }}
              className="rounded-full w-full py-3 text-white sm:w-max sm:px-5 sm:rounded-xl sm:ml-auto sm:flex text-sm"
            >
              Okay
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default UploadFileDrop;
