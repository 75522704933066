import React, { useEffect, useRef, useState } from "react";
import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";

import { useNavigate, useLocation } from "react-router-dom";

import "./ChatBody.css";
import {
  fetchConversation,
  getSessionID,
  updateUserIntakeData,
} from "../../api/_request";
import { useAuth } from "../../auth";
import DelayedRender from "../delayedRender/DelayedRender";
import ContactCard from "../cards/contactCard/ContactCard";
import MediaCard from "../cards/mediaCard/MediaCard";
import {
  avatarImageClass,
  buttonItemClass,
  responseAreaClass,
  responseButtonsClass,
  serverResponseClass,
} from "../../helpers/helperClasses";
import Cookies from "js-cookie";

import { RefreshCw } from "lucide-react";
import { addMessage, getMessages } from "../../indexedDB/dbHelper";
import { useLanguage } from "../../context/LanguageContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import {
  createPayloadNew,
  getSubDomain,
  primaryColorValue,
  scrollChatToBottom,
  sendMessageToApiCore,
  useSearchParamsFunction,
} from "../../lib/utils";
import HtmlRenderCard from "../cards/htmlRenderer/HtmlRenderCard";

import AudioChatResponse from "./chatResponses/AudioChatResponse";
import NotLoggedInChatBody from "./NotLoggedInChatBody";

import SignatureDrawer from "../drawer/SignatureDrawer";
import { Signature } from "lucide-react";
import { ArrowUpRight } from "lucide-react";
import { toast } from "sonner";
import SuggestionItemRounded from "../suggestions/SuggestionItemRounded";
import { ChevronRightIcon } from "lucide-react";
import { ChevronLeftIcon } from "lucide-react";
import { Button } from "@headlessui/react";
import { Sparkle } from "lucide-react";

const ChatBody: React.FC = () => {
  const { state, dispatch } = useAppState();
  const { translate } = useLanguage();
  const { currentUser } = useAuth();
  const uniqueID = crypto.randomUUID().toString();
  const navigate = useNavigate();
  const cachedLanguages = sessionStorage.getItem("languagesList");
  const isInitialRender = useRef(true);

  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollAmount = 200; // Define how much to scroll when clicking the arrows

  const search = useLocation().search;
  const searchBarParams = new URLSearchParams(search);

  const token_url = searchBarParams.get("token");
  const cookie_token = Cookies.get("authToken");

  //Search Params
  const mood = searchBarParams.get("mood");

  const redirectionURL = searchBarParams.get("redirectURL");
  const messageValue = searchBarParams.get("message");
  const payloadValue = searchBarParams.get("payload");
  const buttonValue = searchBarParams.get("button_id");
  const notificationID = searchBarParams.get("notification_id");
  const customID = searchBarParams.get("custom_id");

  const searchParamsFromFunction = useSearchParamsFunction(search);
  //const stellaSessionID = sessionStorage.getItem("stella_s_id");

  //States
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const [regenerate, setRegenerate] = useState<boolean>(false);
  const [previousMessages, setPreviousMessages] = useState<any>([]);
  const [isDelayedRenderingComplete, setIsDelayedRenderingComplete] =
    useState<boolean>(false);
  const [recallSessionID, setRecallSessionID] = useState<boolean>(false);
  const [resDelay, setResDelay] = useState<number>(1000);

  const [signatureDrawer, setSignatureDrawer] = useState<boolean>(false);

  //Use Effects for initialMessage
  useEffect(() => {
    let payload = createPayloadNew(state, searchParamsFromFunction);
    //let payload = createPayload();

    if (
      Object.keys(payload).length &&
      state.isUserLoggedIn &&
      state?.chatHistory?.length == 0 &&
      state.sessionID !== ""
    ) {
      //loadMessages();
      fetchInitialConversation(state.userID, payload);
    }
  }, [messageValue, buttonValue, state.sessionID, state.isUserLoggedIn]);

  // Scroll chat box to the bottom
  useEffect(() => {
    scrollChatToBottom(chatHistoryRef);
  }, [state.chatHistory]);

  

  async function loadMessages() {
    const loadedMessages = await getMessages();

    // dispatch({
    //   type: ActionTypes.UPDATE_CHAT_HISTORY,
    //   payload: loadedMessages,
    // });
    setPreviousMessages(loadedMessages);
  }

  /*Log saved messages inside indexedDB*/

  const fetchInitialConversation = async (userID: string, body: any) => {
    const initialConversation = await fetchConversation(
      userID,
      JSON.stringify(body)
    );

    if (initialConversation) {
      let delay =
        +initialConversation.responseTime > 1000
          ? 0
          : 1000 - +initialConversation.responseTime;

      setResDelay(delay);

      const messageBody = {
        _id: uniqueID,
        response: "server",
        data: initialConversation.responseData,
        avatar: state.selectedFace,
      };
      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [messageBody],
      });
    }
  };

  const handleRenderComplete = (isCompleted: boolean) => {
    if (isCompleted) {
      // Perform actions or handle completion status
      scrollChatToBottom(chatHistoryRef);
      setShowAnimation(false);
      // setIsDelayedRenderingComplete(true)
    } else {
      scrollChatToBottom(chatHistoryRef);
      setShowAnimation(true);
      //setIsDelayedRenderingComplete(false)
    }
  };

  const handleSmartActionables = async (action: string | null | undefined) => {
    if (action === "smart_worksheet_random") {
      const selfRequestArray = state?.chatHistory?.filter(
        (item: any) => item.response === "self"
      );

      const lastRequest = selfRequestArray[selfRequestArray.length - 1];

      if (lastRequest !== undefined && lastRequest !== null) {
        dispatch({
          type: ActionTypes.UPDATE_CHAT_HISTORY,
          payload: [...state.chatHistory.slice(0, -2), lastRequest],
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_CHAT_HISTORY,
          payload: [],
        });
      }
      setRegenerate(true);
      await sendMessageToApiCore(
        "smart_action",
        action,
        state,
        dispatch,
        handleExtraUpdatesComponent2
      );
    }
    if (action === "smart_redirect") {
      if (redirectionURL) {
        window.location.href = redirectionURL;
      } else {
        const domain = `https://${getSubDomain()}`;
        window.location.href = domain;
        //window.open(domain)
      }
    }

    if (action === "smart_signature") {
      setSignatureDrawer(true);
    }
  };

  const handleOption = async (emotion: {
    button_id?: React.Key | null | undefined;
    button_text: any;
    button_action?: any;
  }) => {
    if (emotion?.button_action) {
      handleSmartActionables(emotion?.button_action);
    } else {
      const newMessage = {
        response: "self",
        type: "button_id",
        buttonID: emotion.button_id,
        message: emotion.button_text,
        avatar: currentUser?.ProfileImage,
      };
      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [...state.chatHistory, newMessage, "loading"],
      });
    }

    // sendMessageToApi("button_id", emotion.button_id);
    //sendMessageToApi("button_id", 4060);
  };

  const handleRedirectFromURL = (url: string) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: "REDIRECT_TO_URL",
          url,
        })
      );
    } else {
      window.open(encodeURI(url), "_blank");
      //console.log("REDIRECT URL", url);
    }
  };

  const handleExtraUpdatesComponent2 = (res: any, delay: any) => {
    setRegenerate(false);
    //console.log("Additional handling for Component 2 after API response", res, delay);
    // Additional logic specific to Component 2, e.g., updating UI or state
  };

  const handleSmartButton = async (type: string, value: string) => {
    if (value === "smart_worksheet_random") {
      const selfRequestArray = state?.chatHistory?.filter(
        (item: any) => item.response === "self"
      );

      const lastRequest = selfRequestArray[selfRequestArray.length - 1];

      if (lastRequest !== undefined && lastRequest !== null) {
        dispatch({
          type: ActionTypes.UPDATE_CHAT_HISTORY,
          payload: [...state.chatHistory.slice(0, -2), lastRequest],
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_CHAT_HISTORY,
          payload: [],
        });
      }
      setRegenerate(true);
      await sendMessageToApiCore(
        "smart_action",
        value,
        state,
        dispatch,
        handleExtraUpdatesComponent2
      );
    } else if (value === "smart_redirect") {
      if (redirectionURL) {
        window.location.href = redirectionURL;
      } else {
        window.location.href = getSubDomain();
      }
    } else if (value === "smart_signature") {
      setSignatureDrawer(true);
    }
  };

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.25)` // Adjust the alpha value as needed
    : "";

  const onCloseSignature = () => {
    setSignatureDrawer(false);
  };

  const scrollToLeft = () => {
    // const scrollRef = row === 1 ? scrollRefRow1.current : scrollRefRow2.current;
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollToRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div
      className="flex-1 overflow-y-auto px-3 py-[105px] md:pt-[80px] md:pb-5 space-y-4 my-3 Chat--Body h-screen sm:h-full sm:max-h-[calc(100%-87px)]"
      ref={chatHistoryRef}
    >
      <SignatureDrawer isOpen={signatureDrawer} onClose={onCloseSignature} />

      {!state.isUserLoggedIn ? (
        !token_url && !cookie_token ? (
          <NotLoggedInChatBody />
        ) : (
          <div className="max-w-6xl w-full mx-auto">
            <div
              id="wave-animation"
              className={`${state.chatHistory.length > 0 && "ml-12"}`}
            >
              <div id="loading-bubble" className="bg-muted dark:bg-muted-dark">
                <div className="spinner">
                  <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                  <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                  <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          {state?.chatHistory?.map((chat, i) => {
            const isLatest = i === state.chatHistory.length - 1;

            if (chat.response === "server") {
              return (
                <div className="space-y-3">
                  <div key={chat._id} className={responseAreaClass}>
                    {chat?.data !== undefined && (
                      <div className="avatar relative">
                        <img
                          src={chat?.avatar || state?.selectedFace}
                          alt="My Avatar"
                          className={`${avatarImageClass} ml-auto`}
                        />
                      </div>
                    )}

                    <div className="space-y-2 response--container w-[85%]">
                      {chat?.data?.map((item: any, i: number) => (
                        <div key={i}>
                          <DelayedRender
                            delay={i * 1000}
                            onComplete={handleRenderComplete}
                          >
                            {(item.text ||
                              item.audio ||
                              item.buttons ||
                              item.html ||
                              item.content ||
                              item.user) && (
                              <div
                                className={`response items-center ${
                                  state?.chatDirection
                                } ${serverResponseClass} ${
                                  item?.user || item?.content
                                    ? "Card--Response"
                                    : ""
                                }`}
                              >
                                {/* min-h-[35px] flex items-center in span tag when smart button is implemented */}
                                {item.text && (
                                  <div className="flex items-center gap-2">
                                    <div
                                      className="flex-1"
                                      dangerouslySetInnerHTML={{
                                        __html: `<div>${item.text
                                          .split("\n")
                                          .map((line: any) => {
                                            const sanitizedLine = line.replace(
                                              /[+*]/g,
                                              ""
                                            );
                                            if (line.startsWith("**")) {
                                              return `<p style="font-weight:bold;">${sanitizedLine}</p>`;
                                            } else if (line.startsWith("*")) {
                                              return `<li>${sanitizedLine.slice(
                                                0
                                              )}</li>`;
                                            } else if (/^\d+\./.test(line)) {
                                              return `<p>${sanitizedLine}</p>`;
                                            } else {
                                              return sanitizedLine;
                                            }
                                          })
                                          .join("<br/>")}</div>`,
                                      }}
                                    />

                                    {/* {item.audio && (
                                      <div className="flex items-center gap-3 w-max">
                                        <AudioResponse
                                          audioUrl={item.audio}
                                          responseType="server"
                                        />
                                      </div>
                                    )} */}
                                  </div>
                                )}

                                {item.buttons && (
                                  <div className={responseButtonsClass}>
                                    {item.buttons.map(
                                      (btn: {
                                        button_id: React.Key | null | undefined;
                                        button_text:
                                          | string
                                          | number
                                          | boolean
                                          | React.ReactElement<
                                              any,
                                              | string
                                              | React.JSXElementConstructor<any>
                                            >
                                          | Iterable<React.ReactNode>
                                          | React.ReactPortal
                                          | null
                                          | undefined;
                                      }) => (
                                        <div
                                          key={btn.button_id}
                                          className={`${buttonItemClass} transition-colors ease-in-out duration-200`}
                                          onClick={() => handleOption(btn)}
                                        >
                                          {btn.button_text}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}

                                {
                                  item?.html && (
                                    <HtmlRenderCard htmlContent={item.html} />
                                  )
                                  // (!item?.html.includes("https://") ? (
                                  //   <div
                                  //     className="text-sm font-medium"
                                  //     dangerouslySetInnerHTML={{
                                  //       __html: item.html,
                                  //     }}
                                  //   ></div>
                                  // ) : (
                                  //   <HtmlLinkCard
                                  //     text={parseHtmlString(item.html).text}
                                  //     url={parseHtmlString(item.html).url}
                                  //   />
                                  // ))
                                }

                                {item?.content && (
                                  <MediaCard
                                    categoryID={item.content.category_id}
                                    id={item.content.content_id}
                                    title={item.content.title}
                                    type={item.content.type}
                                    description={item.content.description}
                                    thumbnail={item.content.thumb_image}
                                    largeImage={item.content.large_image}
                                    videoURL={item.content.video_url}
                                    audioURL={item.content.audio_url}
                                    video={item.content.video}
                                  />
                                )}

                                {item?.user && (
                                  <ContactCard
                                    key={item.user.id}
                                    id={item.user.id}
                                    fee={item.user.fee}
                                    description={item.user.description}
                                    name={`${item.user.name}`}
                                    image={`${item.user.image}`}
                                    profession={`${item.user.category}`}
                                    expInYears={`${item.user.experience}`}
                                    languages={`${item?.user.language}`}
                                    url={item.user.expert_detail_page}
                                    banner={item?.user.Expert_Banner}
                                  />
                                )}
                              </div>
                            )}
                          </DelayedRender>

                          {item.web_url && handleRedirectFromURL(item.web_url)}
                          {/* SMART BUTTON REFRESH */}
                          {isLatest && item?.smart_action && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <button
                                    style={{
                                      backgroundColor: rgbaColor,
                                      color: `${
                                        state?.appTheme === "Light"
                                          ? "var(--primary)"
                                          : "#FFF"
                                      }`,
                                    }}
                                    className="w-max px-5 xs:max-w-[350px] md:max-w-[500px] h-10  flex items-center justify-center text-primary dark:border dark:border-blue-900 rounded-xl dark:text-white gap-3 mx-auto my-4"
                                    onClick={() =>
                                      handleSmartActionables(item?.smart_action)
                                    }
                                  >
                                    {item?.smart_action ===
                                      "smart_redirect" && (
                                      <>
                                        <ArrowUpRight className="w-4 h-4" />
                                        <span className="font-medium text-sm">
                                          Proceed
                                        </span>
                                      </>
                                    )}
                                    {item?.smart_action ===
                                      "smart_signature" && (
                                      <>
                                        <Signature className="w-4 h-4" />
                                        <span className="font-medium text-sm">
                                          Sign
                                        </span>
                                      </>
                                    )}

                                    {item?.smart_action ===
                                      "smart_worksheet_random" && (
                                      <>
                                        <RefreshCw className="w-4 h-4" />
                                        <span className="font-medium text-sm">
                                          Refresh Suggestion
                                        </span>
                                      </>
                                    )}
                                  </button>
                                </TooltipTrigger>
                                <TooltipContent className="bg-black px-2 py-1 rounded-md">
                                  <p className="text-white text-xs">
                                    {item?.smart_action === "smart_redirect" &&
                                      "Proceed"}

                                    {item?.smart_action === "smart_signature" &&
                                      "Signature"}

                                    {item?.smart_action ===
                                      "smart_worksheet_random" && "Refresh"}
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className={`w-full max-w-6xl mx-auto space-y-2 py-5 relative ${
                      state?.showSmartSuggestionArea ? "" : "hidden"
                    }`}
                  >
                    {/* <button
                      className="absolute -left-2 md:left-5 top-1/2 -translate-x-1/2 z-10 bg-gray-200 dark:bg-gray-700 p-2 rounded-full hidden md:block"
                      onClick={scrollToLeft}
                    >
                      <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
                    </button>

                    <button
                      className="absolute -right-2 md:-right-5 top-[46%] -translate-x-1/2  z-10 bg-gray-200 dark:bg-gray-700 p-2 rounded-full hidden md:block"
                      onClick={scrollToRight}
                    >
                      <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                    </button> */}
                    <div className="flex items-center gap-6">
                      <h6 className="font-bold text-lg   bg-gradient-to-r from-blue-500 via-red-500 to-pink-400 inline-block text-transparent bg-clip-text">
                        Suggestions for you
                      </h6>

                      <div className="flex items-center gap-2">
                        <button
                          className=" bg-gray-200 p-1 rounded-full hidden md:block"
                          onClick={scrollToLeft}
                        >
                          <ChevronLeftIcon className="h-5 w-5 text-gray-500 " />
                        </button>

                        <button
                          className=" bg-gray-200  p-1 rounded-full hidden md:block"
                          onClick={scrollToRight}
                        >
                          <ChevronRightIcon className="h-5 w-5 text-gray-500 " />
                        </button>
                      </div>
                    </div>

                    <div
                      className="w-full relative flex gap-3 items-center py-2 overflow-x-auto suggestionScroll"
                      ref={scrollRef}
                    >
                      <SuggestionItemRounded
                        img="https://images.pexels.com/photos/5327585/pexels-photo-5327585.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        type="doctor"
                        title="Dr. Jane Doe"
                        description={"Mental Health and Wellness Coach"}
                        //  videoURL="https://player.vimeo.com/video/916442775?h=1555c22e78"
                      />
                      <SuggestionItemRounded
                        img="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2FAppImages%2F1.NewFortis%2FPERFORMANCE%20ANXIETY.jpg?alt=media&token=9739432b-be6a-4cdc-9d1e-7325db5814d1"
                        type="video"
                        title="Easy ways to reduce anxiety"
                        // description={"Mental Health and Wellness Coach"}
                        videoURL="https://player.vimeo.com/video/916442775?h=1555c22e78"
                      />
                      <SuggestionItemRounded
                        img="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                        type="audio"
                        title="Relaxation music for Yoga"
                        // description={"Mental Health and Wellness Coach"}
                        audioURL="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FPodcasts%2FAudio%2FRelaxation%20music%20for%20Yoga.mp3?alt=media&token=3a5a5ad0-1c26-4cc2-b1cd-600ac1fefbfc"
                      />

                      <SuggestionItemRounded
                        img="https://images.pexels.com/photos/5327585/pexels-photo-5327585.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        type="doctor"
                        title="Dr. Jane Doe"
                        description={"Mental Health and Wellness Coach"}
                        //  videoURL="https://player.vimeo.com/video/916442775?h=1555c22e78"
                      />
                      <SuggestionItemRounded
                        img="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2FAppImages%2F1.NewFortis%2FPERFORMANCE%20ANXIETY.jpg?alt=media&token=9739432b-be6a-4cdc-9d1e-7325db5814d1"
                        type="video"
                        title="Easy ways to reduce anxiety"
                        // description={"Mental Health and Wellness Coach"}
                        videoURL="https://player.vimeo.com/video/916442775?h=1555c22e78"
                      />
                      <SuggestionItemRounded
                        img="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                        type="audio"
                        title="Relaxation music for Yoga"
                        // description={"Mental Health and Wellness Coach"}
                        audioURL="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2FContent%2FPodcasts%2FAudio%2FRelaxation%20music%20for%20Yoga.mp3?alt=media&token=3a5a5ad0-1c26-4cc2-b1cd-600ac1fefbfc"
                      />
                    </div>
                  </div>
                </div>
              );
            } else if (chat === "loading") {
              return (
                <div className="flex max-w-6xl mx-auto">
                  <div id="wave-animation" key={chat.id} className="">
                    <div
                      id="loading-bubble"
                      className="bg-muted dark:bg-muted-dark"
                    >
                      <div className="spinner">
                        <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                        <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                        <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (chat.response === "update") {
              /* Feature to be implemented that shows change in Language and Avatar */
              return (
                <div
                  key={i}
                  className="text-xs sm:text-sm py-2 px-4 w-max flex mx-auto bg-yellow-100/80 dark:bg-green-500/10 text-amber-700 dark:text-green-400 rounded-xl ring-1 ring-inset ring-amber-600/10 dark:ring-green-500/20 max-w-xs sm:max-w-sm"
                >
                  {chat.message}
                </div>
              );
            } else {
              return <AudioChatResponse chat={chat} />;
            }
          })}

          {(showAnimation || regenerate) && (
            <div className="flex max-w-6xl mx-auto">
              <div
                id="wave-animation"
                className={`${state.chatHistory.length > 0 && "ml-12"}`}
              >
                <div
                  id="loading-bubble"
                  className="bg-muted dark:bg-muted-dark"
                >
                  <div className="spinner">
                    <div className="bounce1 bg-slate-400/50 dark:bg-slate-200"></div>
                    <div className="bounce2 bg-slate-400/50 dark:bg-slate-200"></div>
                    <div className="bounce3 bg-slate-400/50 dark:bg-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatBody;
