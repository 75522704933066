import { useNavigate, useLocation } from "react-router-dom";
import { getSubDomain } from "../../../lib/utils";
import { useAppState } from "../../../context/AppStateContext";

const ContactCard = ({
  id,
  name,
  image,
  profession,
  expInYears,
  languages,
  url,
  banner,
}: ContactCardProps) => {
  const { state } = useAppState();
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchBarParams = new URLSearchParams(search);
  const appURL = searchBarParams.get("appURL");
  const subdomain = getSubDomain();

  const redirectToExpert = (expertUrl: string) => {
    //  console.log("expertUrl", expertUrl)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: "REDIRECT_TO_EXPERT", url: id, expertUrl })
      );
      return;
    }

    const locationUrls: any = {
      in: "https://my.unitedwecare.com/",
      india: "https://my.unitedwecare.com/",
      us: "https://us.unitedwecare.com/",
      "united states": "https://us.unitedwecare.com/",
      au: `https://booking.${subdomain}/`,
      australia: `https://booking.${subdomain}/`,
      default: `https://booking.${subdomain}/`,
    };

    const locationKey = state?.userLocation?.toLowerCase() || "default";
    const redirectUrl = locationUrls[locationKey];

    if (redirectUrl) {
      window.open(`${redirectUrl}${expertUrl}`, "_blank");
    }
  };

  const handleExpertBooking = (
    expertUrl: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    redirectToExpert(expertUrl);
  };

  return (
    <div
      id={id}
      className="ContactCard w-full min-w-[276px] max-w-[276px] md:max-w-[320px] md:min-w-[320px] rounded-2xl bg-white dark:bg-[#080d19] border border-solid border-slate-300 dark:border-slate-800"
      onClick={(e: any) => handleExpertBooking(url, e)}
    >
      <div className="ContactCard--Content flex gap-0">
        <img
          src={image}
          alt=""
          className="rounded-l-2xl w-[100px] md:w-[120px] object-cover"
        />
        <div className="w-full">
          {banner && (
            <img alt="" src={banner} className="rounded-se-2xl bg-white" />
          )}

          <div className="ContactCard--desc flex flex-col gap-1 py-2 px-2 md:px-3">
            <h3 className="text-lg font-bold text-slate-900 dark:text-white line-clamp-2">
              {name}
            </h3>

            <div className="space-y-0.5">
              {profession && (
                <p className="font-medium text-sm text-slate-700 dark:text-slate-300">
                  {profession}
                </p>
              )}

              {expInYears && (
                <p className="font-medium text-xs text-slate-500 dark:text-slate-400">
                  {expInYears} years of experience
                </p>
              )}

              {languages?.length > 0 && (
                <p className="font-medium text-xs text-slate-500 dark:text-slate-400 line-clamp-1">
                  {languages}
                </p>
              )}
            </div>

            <button
              type="button"
              style={{ backgroundColor: "var(--primary)" }}
              className="bookExpert--Btn text-center bg-primary text-white p-2.5 mt-2 rounded-lg text-xs shadow-md"
              onClick={(e) => handleExpertBooking(url, e)}
            >
              Book now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
